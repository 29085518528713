// @ts-strict-ignore
import Router, { useRouter } from 'next/router';
import styled from 'styled-components';
import { useSelector } from 'src/store/store';
import WithErrorHandling from 'src/components/hoc/withErrorHandling';
import { DefaultLayout } from 'src/components/layouts';
import LoginBox, { Container } from 'src/modules/shared/modal/components/login/loginBox';
import SignupBox from 'src/modules/shared/modal/components/signup/signupBox';
import { PageContext } from 'src/constants/types';
import { getLoggedInRedirectUrl } from 'src/utils/context';
import { CloseButton } from 'styles-js/modals';
type Props = {
  isSignup?: boolean;
  redirect?: string;
  username?: string;
};
const Page = ({
  isSignup,
  redirect,
  username
}: Props) => {
  const router = useRouter();
  const isModalSet = useSelector(({
    modal
  }) => modal.modalType);
  const doRedirect = redirect && redirect !== '/' ? () => router.replace(redirect) : null;
  return <DefaultLayout data-sentry-element="DefaultLayout" data-sentry-component="Page" data-sentry-source-file="login.tsx">
      {!isModalSet && <Center>
          {isSignup ? <SignupBox redirectPage={doRedirect} /> : <LoginBox username={username} />}
        </Center>}
    </DefaultLayout>;
};
Page.getInitialProps = async ({
  res,
  query,
  store: {
    getState
  }
}: PageContext) => {
  const redirect = getLoggedInRedirectUrl(query?.url, query?.redirect);
  if (getState().context.currentUser) {
    if (res) {
      res.writeHead(302, {
        Location: redirect
      }).end();
    } else {
      Router.push(redirect);
    }
  }
  return {
    username: query?.recipient,
    redirect,
    isSignup: query?.isSignup
  };
};
export default WithErrorHandling(Page);
const Center = styled.div`
  @media (${({
  theme
}) => theme.overScreenXsmall}) {
    width: 390px;
    margin: 40px auto;
    box-shadow: ${({
  theme
}) => theme.shadowDefault};
  }
  @media (${({
  theme
}) => theme.overScreenSmall}) {
    margin: 25px auto;
  }
  ${Container} {
    padding: 40px;
  }
  ${CloseButton} {
    display: none;
  }
`;